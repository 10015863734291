// Home.js

import React from 'react';
import Header from './header';
import Intro from './intro';
import Info from './info';
import Info2 from './info-2';
import Features from './features'
import NH1 from './nh1';
import Team from './team'
import Research from './research'
// import Braindort from './braindort'
// import Contact from './contact'
// import Contact from './contact';
// import esp_img from './esp.png';

import ra_img from './assets/accessibility.png';
import focus_img from './assets/focus.png';
import mindful_img from './assets/mindful.png';

import './App.css';
import './fonts.css'

function Home() {
  return (
    <div className="Home">
      <Header />
      {/* <Contact />  */}
      
      <div className="site-container">
        <Intro />
        <Info
          title="Handran is a Neurotechnology company"
          text="Neural activity, design, and AI converge for an immersive user experience awaiting a paradigm shift in human-computer interaction."
          title2="We simplify EEG integration"
          text2="Making it more accessible than before, transforming everyday wearables that are worn on your head into brain-controlled devices."
        />
        
        {/* 
        <Info
          title="EEG Signal Processor"
          text="Accelerated with on-device neural processing ensuring security and privacy."
          imageSrc={esp_img}
        /> */}
        <Info2        />
        <NH1 />
        <Features 
        heading="Redefining Accessibility" 
        subtext="Handran BCI app serves your need to interact 
                without moving a muscle, vastly improving quality of life." 
        imageSrc={ra_img} 
        altText="Redefining Accessibility"
      />
      <Features 
        heading="Focus." 
        subtext="Tune in with your own unmatched potential
        Elevate attention span, attain a consistent flow" 
        imageSrc={focus_img} 
        altText="Focus"
      />
        
        <Features 
        heading="Mindfullness." 
        subtext="Embark on a journey to better manage your daily life,
        A personalized portal to recalibrate the mind" 
        imageSrc={mindful_img} 
        altText="Mindfullness"
      />
        
        
        <Research/>


        <Team />
        
        
      </div>
      
    </div>
   
  );
}

export default Home;





