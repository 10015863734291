import React from 'react';
// import esp_img from './esp.png';
// import braindort from './braindort.svg';
import './info2.css';
import Braindort from './braindort'

const Info2Section = () => {

  return (
    <div className="info2-container bg-3" id="product">
      <div className="info2-left">
        {/* <div className='left-1'>
        <div className="heading-text dm-sans-bold">We simplify EEG integration</div>
        <div className="body-text dm-mono-regular">Making it more accessible than before, transforming everyday wearables that are worn on your head into brain-controlled devices.</div>
        </div> */}

        <div className='left-2'>
       <div className="heading-text dm-sans-bold">EEG Signal Processor</div>
       <div className="body-text dm-mono-regular">Accelerated with on-device neural processing ensuring security and privacy.</div>
       </div>
      </div>
      <div className="info2-right">
        {/* <img src={esp_img} className="info2-image" alt='ESP' />
        <img src={braindort} className="info2-image" alt="Powered By BrainDORT" /> */}
         <Braindort />
      </div>
    </div>
  );
};

export default Info2Section;

