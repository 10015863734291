import React from 'react';
import ESP from './assets/ESP.htm'
import './App.css';

function Braindort() {
  return (
    <div className="braindort-3d" >
      <iframe
        src={ESP} 
        frameBorder="0"
        style={{ display: 'block', width: '100%', height: '100%' , overflow:'none' }}
        title="3D Model"
      ></iframe> 

{/* <iframe src='https://my.spline.design/untitled-95f1614af5e3ef553d9d2bbae49714f3/'  frameborder='0' width='100%' height='100%'></iframe> */}
    </div>
  );
}

export default Braindort;
