import React from 'react';
import './research.css';

const researchTopics = [
  {
    main: "Human Computer Interaction",
    subcategories: ["Neuromarketing", "Gaming"]
  },
  {
    main: "Healthcare and Wellbeing",
    subcategories: ["Neurological Disorders", "Workspace Wellness"]
  },
  {
    main: "Industrial",
    subcategories: ["Robotics", "Transport Safety", "Spatial Computing"]
  }
];

const Research = () => {
  return (
    <div className="research-container" id="research">
      <div className='title-text dm-sans-bold'>Research & Development</div>
      <div className="research-grid">
        {researchTopics.map((topic, index) => (
          <div key={index} className="research-box ">
            <div className="main-category dm-sans-bold body-text">{topic.main}</div>
            <div className="subcategories dm-mono-regular">
              {topic.subcategories.map((sub, subIndex) => (
                <div key={subIndex} className="subcategory-box body-text">
                  {sub}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Research;
