// InfoSection.js
import React from 'react';
import './App.css';

const InfoSection = ({ title, text, imageSrc, title2, text2, imageSrc2}) => {
  return (
    <div className="info-container bg-3" id="about">
      <div className="info-text">
        {title && <div className="title-text dm-sans-bold">{title}</div>}
        {imageSrc && <img src={imageSrc} className="info-image" alt={title} />}
        <div className="body-text dm-mono-regular">{text}</div>
      </div>
      <div className="info-text">
        {title2 && <div className="heading-text dm-sans-bold">{title2}</div>}
        {imageSrc2 && <img src={imageSrc2} className="info-image" alt={title2} />}
        <div className="body-text dm-mono-regular">{text2}</div>
      </div>
   
    </div>
  );
};

export default InfoSection;
