import React, { forwardRef, useState } from 'react';
import './contact.css';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore/lite';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyCoY9G5iP7YbrrgeWdsRB_Fu7sX0LfDm1U",
  authDomain: "handran-test.firebaseapp.com",
  projectId: "handran-test",
  storageBucket: "handran-test.appspot.com",
  messagingSenderId: "411483818541",
  appId: "1:411483818541:web:e94b857d2873c7261006a4",
  measurementId: "G-306Q2V51TZ"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const ContactForm = forwardRef(({ onClose }, ref) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = await addDoc(collection(db, 'contact_form'), formData);
      console.log('Document written with ID: ', docRef.id);
      alert('Message sent successfully!');
      setFormData({
        name: '',
        email: '',
        phone: '',
        message: ''
      });
      onClose();
    } catch (e) {
      console.error('Error adding document: ', e);
      alert('Failed to send message. Please try again.');
    }
  };

  return (
    <div className="contact-form-overlay">
      <div className="contact-form" ref={ref}>
        <button className="close-button" onClick={onClose}>X</button>
        <h2 className='contact-text dm-sans-bold'>Join Beta </h2>
        <form onSubmit={handleSubmit}>
          <div>
            <input
              className="dm-sans-bold"
              type="text"
              id="name"
              name="name"
              placeholder="Full Name"
              value={formData.name}
              onChange={handleChange} required
            />
          </div>
          <div className="flex-row">
            <input
              className="dm-sans-bold input-flex"
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange} required
            />
            <input
              className="dm-sans-bold input-flex"
              type="phone"
              id="phone"
              name="phone"
              placeholder="Phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
          <div>
            <textarea
              className="dm-sans-bold"
              id="message"
              name="message"
              placeholder="Message"
              value={formData.message}
              onChange={handleChange} required
            />
          </div>
          <button type="submit" className="submit-button">Submit</button>
        </form>
      </div>
    </div>
  );
});

export default ContactForm;