import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import SplitType from 'split-type';
import large_logo from './handran_large.svg';
import './App.css';

const IntroSection = () => {
  const titleRef = useRef(null);

  useEffect(() => {
    // Split the heading text into individual words
    const splitText = new SplitType(titleRef.current, { types: 'words' });
    const words = splitText.words;

    // Animate each word with GSAP
    gsap.fromTo(
      words,
      { 
        y: 100,
        opacity: 0
      },
      {
        y: 0,
        opacity: 1,
        stagger: 0.1, // Adjusted to create a smooth word-by-word effect
        duration: 2,
        ease: 'power4.out',
      }
    );
  }, []);

  return (
    <div className="intro-container">
      <div className="intro-text">
        <div ref={titleRef} className="title-text h1 dm-sans-bold">
        Gateway to the power of your mind.

        </div>
        <span className="dm-mono-regular">No physical interaction is required,</span><br />
        <span className="dm-mono-medium">Just your intent is enough</span>
      </div>
      <div className="logo-large">
        <img src={large_logo} className="handran-logo-large" alt="logo" />
      </div>
    </div>
  );
};

export default IntroSection;
