import React from 'react';
import './team.css';

import Avatar1 from './assets/hannahmontana.png'
import Avatar2 from './assets/veeresh.png'
import Avatar3 from './assets/aditya.png'
import Avatar4 from './assets/sid.png'
import Avatar5 from './assets/keroseein.png'
import Avatar6 from './assets/nihal.png'


const Team = () => {
  const teamMembers = [
    {
      name: 'Hemanth Chandran',
      title: 'Founder',
      role: 'ML Engineer',
      image: Avatar1,
    },
    {
      name: 'Veeresh Kumbar',
      title: 'Senior iOS Engineer',
      role: 'Uber',
      image: Avatar2,
    },
    {
      name: 'Aditya Ajaykumar',
      title: 'Microsystems Engineer',
      role: 'CSS Engineer, NetworkRail, London',
      image: Avatar3,
    },
    {
      name: 'Siddrameshwar Jeer',
      title: 'Senior Software Engineer',
      role: 'Rakuten',
      image: Avatar4,
    },
    {
      name: 'Kiran TS',
      title: 'Design Engineer',
      role: 'Product Engineer, Maybank',
      image: Avatar5,
    },
    {
      name: 'Nihal Shetty',
      title: 'Marketing Strategist',
      role: 'Recording Engineer',
      image: Avatar6,
    },
  ];

  return (
    <div className='team-wrapper'>
      <div className='title-text  dm-sans-bold'>Team</div>
    <div className="team-container">
      {teamMembers.map((member, index) => (
        <div key={index} className="team-member">
          <div className="team-image">
            <img src={member.image} alt={member.name} />
          </div>
          <div className="team-info">
            <h3 className='dm-sans-bold'>{member.name}</h3>
            <p className="title dm-mono-regular">{member.title}</p>
          </div>
        </div>
      ))}
    </div>
    </div>
  );
};

export default Team;


// remove ex company