import React, { useLayoutEffect,useEffect, useState, useRef, useCallback } from 'react';
import { gsap } from 'gsap';
import logo from './logo.svg';
import './App.css';
import ContactForm from './contact';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);






const Header = () => {
  const [activeLink, setActiveLink] = useState('');
  const [showContactForm, setShowContactForm] = useState(false);
  const formRef = useRef(null);
  const overlayRef = useRef(null);


  const [isMenuOpen, setIsMenuOpen] = useState(false); // New state to toggle hamburger menu


  useEffect(() => {
    // Array of section IDs
    const sections = ['about', 'product', 'research'];

    sections.forEach(section => {
      ScrollTrigger.create({
        trigger: `#${section}`,
        start: "top center+=100", // Adjust this to control when the link becomes active
        end: "bottom center-=100",
        onEnter: () => setActiveLink(section),
        onLeaveBack: () => setActiveLink(''),
       
      });
    });

    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);


  useLayoutEffect(() => {
    const header = document.querySelector(".App-header");
    const contactBtn = document.querySelector(".action-btn");
    const isMobile = window.innerWidth <= 768;

    // Animate header entrance
    gsap.fromTo(header, 
      { opacity: 1, y: -100 }, 
      { opacity: 1, y: 0, duration: 1.5, ease: "power3.out" }
    );
if (!isMobile) { 
    // Header hover effect to slightly scale and change background
    header.addEventListener("mouseenter", () => {
      gsap.to(header, {
        scale: 1.02,
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        border: "2px solid black",
        boxShadow: "rgba(17, 12, 46, 0.80) 0px 48px 100px 0px",
        duration: 1,
        ease: "expo.out",
      });
    });

    header.addEventListener("mouseleave", () => {
      gsap.to(header, {
        scale: 1,
        backgroundColor: "rgba(255, 255, 255, 0)",
        border: "0.1px solid black",
        boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
        duration: 1,
        ease: "expo.out",
      });
    });
  }

    // Mousemove effect to slightly move header towards cursor
    const handleMouseMove = (event) => {
      const { clientX, clientY } = event;
      const { left, top, width, height } = header.getBoundingClientRect();
      const moveX = (clientX - (left + width / 2)) * -0.02;
      const moveY = (clientY - (top + height / 2)) * -0.02;

      gsap.to(header, {
        x: moveX,
        y: moveY,
        duration: 0.3,
        ease: "power2.out",
      });
    };

    header.addEventListener("mousemove", handleMouseMove);

    // Add click event to show the contact form
    contactBtn.addEventListener("click", () => {
      gsap.to(contactBtn, {
        scale: 0.9,
        duration: 0.1,
        ease: "power1.out",
        onComplete: () => {
          gsap.to(contactBtn, {
            scale: 1,
            duration: 0.2,
            ease: "elastic.out(1, 0.5)"
          });
          setShowContactForm(true);
        }
      });
    });

    return () => {
      header.removeEventListener("mouseenter", () => {});
      header.removeEventListener("mouseleave", () => {});
      header.removeEventListener("mousemove", handleMouseMove);
      contactBtn.removeEventListener("click", () => setShowContactForm(true));
    };
  }, []);

  const handleCloseForm = useCallback(() => {
    // Reverse the slide-in animation
    gsap.to(formRef.current, {
      x: '100%',
      opacity: 1,
      duration: 0.5,
      ease: "power1.in",
      onComplete: () => {
        setShowContactForm(false);
      }
    });
  }, []);

  // const handleClickOutside = useCallback((event) => {
  //   if (formRef.current && !formRef.current.contains(event.target)) {
  //     handleCloseForm();
  //   }
  // }, [handleCloseForm]);
  const scrollToSection = (sectionId) => {
    setActiveLink(sectionId);
    gsap.to(window, {
      duration: 2,
      scrollTo: {
        y: `#${sectionId}`,
        offsetY: 70 // Adjust this if your header covers part of the section at the top
      },
      ease: "expo.out" // Easing function for a smooth effect
    });
  };

  useEffect(() => {
    // Array of section IDs
    const sections = ['about', 'product', 'research'];

    sections.forEach(section => {
      ScrollTrigger.create({
        trigger: `#${section}`,
        start: "top center+=100",
        end: "bottom center-=100",
        onEnter: () => setActiveLink(section),
        onLeaveBack: () => setActiveLink(''),
      });
    });

    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };


  useLayoutEffect(() => {
    if (showContactForm && formRef.current) {
      if (overlayRef.current) {
        overlayRef.current.classList.add('show');
      }
      gsap.fromTo(formRef.current, 
        { x: '100%', opacity: 0 },  // Start off-screen to the right
        { x: '0%', opacity: 1, duration: 1, ease: "power4.out" }  // Slide in to cover half the screen
      );

      // Stagger effect for form items
      gsap.from(formRef.current.querySelectorAll('input, textarea, button'), {
        duration: 0.8,
        y: 30,
        opacity: 0,
        stagger: 0.15, // Delay between each item
        ease: "power4.out",
        delay: 0 // Delay start to wait for form slide-in
      });
    } else if (overlayRef.current) {
      overlayRef.current.classList.remove('show');
    }
  }, [showContactForm]);

  return (
    <>
      <header className="App-header dm-mono-regular">
        <img src={logo} className="handran-logo" alt="logo" />
        <div className={`hamburger ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="bar" onClick={() => scrollToSection('about')}></div>
          <div className="bar" onClick={() => scrollToSection('product')}></div>
          <div className="bar" onClick={() => scrollToSection('research')}></div>
        </div>

        <nav className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
          <div className={`nav-link ${activeLink === 'about' ? 'active' : ''}`} onClick={() => scrollToSection('about')}>About</div>
          <div className={`nav-link ${activeLink === 'product' ? 'active' : ''}`} onClick={() => scrollToSection('product')}>Product</div>
          <div className={`nav-link ${activeLink === 'research' ? 'active' : ''}`} onClick={() => scrollToSection('research')}>Research</div>
          <div className="action-cont">
          <div className="action-btn">Beta</div>
        </div>
        </nav>
       
      </header>
      {showContactForm && (
        <div className="contact-form-overlay" ref={overlayRef}>
          <ContactForm ref={formRef} onClose={handleCloseForm} />
        </div>
      )}
    </>
  );
};

export default Header;
