import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import namusic from './assets/NeuroAdaptiveMusic.png';
import './nh1.css';
import nh1_img from './NH1.png'
// import Nh1 from './assets/nh1.htm';

gsap.registerPlugin(ScrollTrigger);

function NH1() {
  useEffect(() => {
    // Pin the right content and scroll the inner content
    if (window.innerWidth > 768) {
    gsap.to(".scrollable-content", {
      yPercent: -25, // Adjust scroll distance to match content
      ease: "none",
      scrollTrigger: {
        trigger: ".nh1-container",
        start: "top top",
        end: "bottom bottom",
        scrub: true,
        pin: ".nh1-content-right",
        anticipatePin: 1,
        markers: false, // Remove markers after testing
      }
    });
  }

    // Create a smooth scale animation for each .right-div element
    gsap.utils.toArray('.right-div').forEach(div => {
      gsap.fromTo(div, 
        { scale: 1 }, 
        { 
          scale: 1, // Scale up slightly for the "pop" effect
          ease: "power1.out", // Use a gentle easing function
          scrollTrigger: {
            trigger: div,
            start: "top center+=100", // Start scaling slightly before the center
            end: "bottom center-=100", // End scaling slightly after the center
            scrub: true, // Smooth out the scroll-linked animation
            markers: false, // Hide markers for a clean experience
          }
        }
      );
    });

  }, []);

  return (
    <div className="nh1-container">
      <div className="nh1-text">
      {/* <p className="nh1-name dm-sans-bolder">NH 1</p> */}
           
      <span className='dm-mono-regular sub-head'>Wireless</span>
        <span className="title-text dm-sans-bold">Neural<br/> Headphone</span>
        <img src={nh1_img} className="nh1-img" alt="NH 1" />
        {/* <iframe
        src={Nh1} 
        frameBorder="0"
        style={{ display: 'block', width: '100%', height: '100%' , overflow:'none'}}
        title="3D Model"
      ></iframe>  */}
      <p className="nh1-name dm-sans-bolder">NH 1</p> 
  
      </div>
      <div className="nh1-content-right">
        <div className="scrollable-content">
          <div className="right-div dm-mono-regular">Opening new avenues for creativity and efficiency</div>
          <div className="right-div middle dm-sans-bold">
            
           
            <img src={namusic} className="na-music" alt="NH 1" />
            Neuro-adaptive music
            <div className='dm-mono-regular'>Tailored to your mood</div>
            </div>

          <div className="right-div dm-mono-regular">Syncs flawlessly with your cognitive processes</div>
        </div>
      </div>
    </div>
  );
}

export default NH1;
