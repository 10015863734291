"use client"
import { ReactLenis } from 'lenis/react'
import Home from './home'

function SmoothScroll() {
  return (
 
    <ReactLenis root  >
       <Home />
     </ReactLenis> 
    
  )
}

export default SmoothScroll;