import React from 'react';
import './features.css';

const Features = ({ heading, subtext, imageSrc, altText }) => {
  return (
    <div className="slide-container">
      <div className="slide-left">
        <div className='slide-text'>
          <div className="heading-text dm-sans-bold">{heading}</div>
        </div>
        <div className='slide-subtext'>
          <div className="body2-text dm-mono-regular">{subtext}</div>
        </div>
      </div>
      <div className="slide-right">
        <img src={imageSrc} className="accessibility" alt={altText} />
      </div>
    </div>
  );
};

export default Features;
